import {ref}             from 'vue';
import ApiListFetcher    from "../../services/api/ApiListFetcher";
import {Api}             from "../../types/api/v1/electrification";
import LaravelIndexResponse = Api.V1.LaravelIndexResponse;
import BannerGroup = Api.V1.BannerGroup;
import BannerGroupUrlLoc from "../../services/api/banner-group/BannerGroupUrlLoc";
import Pagination = Api.V1.Pagination;


export const useBannerGroupList = () => {
    const banners      = ref<Array<BannerGroup>>([]);
    const bannersReady = ref<boolean>(true);

    const pagination = ref<Pagination>({
        current_page: 0,
        last_page   : 0,
        per_page    : 0
    })

    const fetcher = new ApiListFetcher<BannerGroup>(new BannerGroupUrlLoc());

    // @ts-ignore
    const setBanners     = (value: Array<BannerGroup>) => banners.value = value;
    const setMeta        = (value: Pagination) => pagination.value = value;

    const loadBanners = (page: number) => {
        fetcher.index({page: page}).then((data: LaravelIndexResponse<BannerGroup>) => {
            setBanners(data.data);
            setMeta(data.meta);
            bannersReady.value = true;
        });
    }

    const deleteBanner = (id: number) => {
        fetcher.delete(id).then(() => {
            loadBanners(pagination.value.current_page);
        })
    }

    return {banners, bannersReady, setBanners, loadBanners, deleteBanner, pagination};
};