
import {defineComponent, onMounted} from "vue";
import Loading                           from '@/components/Loading.vue';
import {useBannerGroupList}              from "../../logic/banner/banner-group-list";
import Pagination                        from "../uikit/Pagination.vue";

export default defineComponent({
  name      : "BannerList",
  components: {
    Loading: Loading,
    Pagination: Pagination,
  },
  setup() {
    const {banners, bannersReady, loadBanners, deleteBanner, pagination} = useBannerGroupList();

    onMounted(() => loadBanners(1));

    return {banners, bannersReady, deleteBanner, pagination, loadBanners}
  },
})
