<template>
  <h2>Баннеры</h2>
  <Loading v-if="!bannersReady"></Loading>
  <router-link :to="{name: 'banner-group-form'}" class="uk-button uk-button-primary">Добавить группу</router-link>
  <div class="uk-text-center uk-grid-divider uk-margin-top" uk-grid>
    <div v-for="banner in banners">
      <div class="uk-card uk-card-default uk-card-body">
        <div class="banner-badge"><span class="uk-badge">{{ banner.banners.length }}</span></div>
        <button class="uk-modal-close-default" type="button" uk-close  @click="deleteBanner(banner.id)"></button>
        <h3><router-link class="uk-button-link" :to="{name: 'banner-group-edit', params: {requestId: banner.id}}">{{ banner.name }}</router-link></h3>
        <p>Добавлен: {{ banner.created }}</p>
      </div>
    </div>
  </div>
  <Pagination v-model="pagination" :link-count="2" @update:page="loadBanners"></Pagination>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import Loading                           from '@/components/Loading.vue';
import {useBannerGroupList}              from "../../logic/banner/banner-group-list";
import Pagination                        from "../uikit/Pagination.vue";

export default defineComponent({
  name      : "BannerList",
  components: {
    Loading: Loading,
    Pagination: Pagination,
  },
  setup() {
    const {banners, bannersReady, loadBanners, deleteBanner, pagination} = useBannerGroupList();

    onMounted(() => loadBanners(1));

    return {banners, bannersReady, deleteBanner, pagination, loadBanners}
  },
})
</script>

<style scoped>
.banner-badge {
  position: absolute;
  z-index: 1010;
  top: 10px;
  left: 10px;
  padding: 5px;
}
</style>
