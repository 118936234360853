
import {defineComponent} from "vue";
import Pagination        from "./Pagination";

export default defineComponent({
  name      : "Pagination",
  emits: ['update:page'],
  props  : {
    modelValue: {
      type: Object
    },
    linkCount: {
      type: Number,
      value: 3
    }
  },
  methods : {
    getPaginateClass(page: any) {
      if (page.active) {
        return 'uk-active'
      } else if (page.disabled) {
        return 'uk-disabled'
      } else {
        return ''
      }
    },
    updatePage(page: number) {
      // @ts-ignore
      if (this.modelValue.current_page !== page) {
        this.$emit('update:page', page);
      }
    }
  },
  computed: {
    paginationList(): Array<Pagination> {
      let rawPagination = this.modelValue;

      if (!rawPagination || rawPagination.current_page === 0 || !this.linkCount) {
        return [];
      }

      let linkCount = this.linkCount;

      let pagination = [];
      let start     = (rawPagination.current_page - linkCount) > 0 ? rawPagination.current_page - linkCount : 1;
      let end       = (rawPagination.current_page + linkCount) < rawPagination.last_page ? rawPagination.current_page + linkCount : rawPagination.last_page;

      if (rawPagination.current_page !== 1) {
        pagination.push({
          text  : '<span uk-pagination-previous></span>',
          number: rawPagination.current_page - 1,
          active: true,
          disabled: false,
        })
      }


      if (start > 1) {
        pagination.push({
          text    : '1',
          number  : 1,
          active: false,
          disabled: false,
        })

        pagination.push({
          text    : '...',
          number  : 0,
          disabled: true,
          active: false,
        })
      }

      for (let i = start; i <= end; i++) {
        pagination.push({
          text    : i,
          number  : i,
          active: rawPagination.current_page === i,
          disabled: false,
        })
      }

      if (end < rawPagination.last_page) {
        pagination.push({
          text    : '...',
          number  : 0,
          disabled: true,
          active: false,
        })

        pagination.push({
          text  : rawPagination.last_page,
          number: rawPagination.last_page,
          active: false,
          disabled: false,
        })
      }

      if (rawPagination.current_page !== rawPagination.last_page) {
        pagination.push({
          text  : '<span uk-pagination-next></span>',
          number: rawPagination.current_page + 1,
          active: true,
          disabled: false,
        })
      }

      return pagination;
    },
    needShow() {
      return this.paginationList.length > 1;
    }
  }
})
